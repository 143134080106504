<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="Cititor" class="px-5 py-3">
          <v-form>
            <v-container class="py-0" fluid>
              <v-row dense class="text-no-wrap" align="center">
                <v-col cols="12">
                  <v-textarea
                    v-model="record.fields['200'][0].a[0].val"
                    :rules="validateValue('200', 'a')"
                    rows="2"
                    :hide-details="hided"
                    :dense="dense"
                    :label="'200a ' + $t('Titlu')"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="record.fields['200'][0].f[0].val"
                    :rules="validateValue('200', 'f')"
                    :hide-details="hided"
                    :dense="dense"
                    :label="'200f ' + $t('Prima menţ. de resp.')"
                  />
                </v-col>
              </v-row>
              <base-material-tabs
                :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                color="primary"
                show-arrows
                center-active
                centered
                fixed-tabs
              >
                <v-tab>{{ $t("Resp./autor") }}</v-tab>
                <v-tab>{{ $t("Descriere") }}</v-tab>
                <v-tab>{{ $t("Clasificări") }}</v-tab>
                <v-tab>{{ $t("Note") }}</v-tab>
                <v-tab>{{ $t("Legături") }}</v-tab>
                <v-tab>{{ $t("Identificare") }}</v-tab>
                <v-tab-item>
                  <v-subheader>{{ "700a " + $t("Autor principal") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['700']"
                    :render="render"
                    height="50px"
                    collection="ebib_marc_ath"
                    @del="delSubItem('fields.700', $event)"
                    @search="searchRelationMarc('700', 'a', $event, 'ath', 'nume-personal')"
                  />
                  <v-subheader>{{ "701a " + $t("Autor alternativ") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['701']"
                    :render="render"
                    height="50px"
                    collection="ebib_marc_ath"
                    @add="addSubItem('fields.701', $event)"
                    @del="delSubItem('fields.701', $event)"
                    @search="searchRelationMarc('701', 'a', $event, 'ath', 'nume-personal')"
                  />
                  <v-subheader>{{ "702a " + $t("Autor secundar") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['702']"
                    :render="render"
                    height="50px"
                    :show7024="true"
                    collection="ebib_marc_ath"
                    @add="addSubItem('fields.702', $event)"
                    @del="delSubItem('fields.702', $event)"
                    @search="searchRelationMarc('702', 'a', $event, 'ath', 'nume-personal')"
                  />
                  <v-subheader>{{ "710a " + $t("Nume instituţie primară") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['710']"
                    :render="render"
                    height="50px"
                    collection="ebib_marc_ath"
                    @add="addSubItem('fields.710', $event)"
                    @del="delSubItem('fields.710', $event)"
                    @search="searchRelationMarc('710', 'a', $event, 'ath', 'nume-corporatie')"
                  />
                  <v-subheader>{{ "711a " + $t("Nume instituție alternativă") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['711']"
                    :render="render"
                    height="50px"
                    collection="ebib_marc_ath"
                    @add="addSubItem('fields.711', $event)"
                    @del="delSubItem('fields.711', $event)"
                    @search="searchRelationMarc('711', 'a', $event, 'ath', 'nume-corporatie')"
                  />
                </v-tab-item>
                <v-tab-item>
                  <v-row class="mt-4" dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model="record.fields['510'][0].a[0].val"
                        :rules="validateValue('510', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'510a ' + $t('Subtitlu')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['210'][0].d[0].val"
                        :rules="validateValue('210', 'd')"
                        style="width:300px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'210d ' + $t('Data publicării, distribuţiei')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="record.fields['101'][0].a[0].val"
                        :rules="validateValue('101', 'a')"
                        style="width:300px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'101a ' + $t('Limba')"
                        :items="dictionary['ISO-639']"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row dense>
                    <v-col class="pr-sm-3" cols="12" sm="6">
                      <v-row v-for="(fld, ind) in record.fields['606']" :key="'606' + ind">
                        <v-col cols="12">
                          <span class="d-none">{{ render }}</span>
                          <s-autocomplete
                            :value="fld.a[0].val"
                            where='"ctg":"subiect",'
                            :label="'606a ' + $t('Vedetă de subiect')"
                            :dense="dense"
                            :hide-details="hided"
                            :clearable="false"
                            return-object
                            view-type="marc"
                            item-text="fields.250.a.val"
                            item-value="fields.250.a.val"
                            collection="ebib_marc_ath"
                            type="v-autocomplete"
                            @search="searchRelationMarc('606', 'a', ind, 'ath', 'subiect')"
                            @input="setLink('606', 'a', ind, 'ath', 'tezaur', $event)"
                          >
                            <template v-slot:append-outer>
                              <div class="d-inline-flex">
                                <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.606', ind + 1)">
                                  mdi-plus
                                </v-icon>
                                <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.606', ind)">
                                  mdi-minus
                                </v-icon>
                              </div>
                            </template>
                            <template v-slot:append>
                              <v-icon
                                class="pt-1"
                                tabindex="-1"
                                @click.stop.prevent="searchRelationMarc('606', 'a', ind, 'ath', 'subiect')"
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                          </s-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['601']" :key="'601' + ind">
                        <v-col cols="12">
                          <span class="d-none">{{ render }}</span>
                          <s-autocomplete
                            :value="fld.a[0].val"
                            where='"ctg":"nume-corporatie",'
                            :label="'601a ' + $t('Nume de instituţie subiect')"
                            :dense="dense"
                            :hide-details="hided"
                            :clearable="false"
                            return-object
                            view-type="marc"
                            item-text="fields.210.a.val"
                            item-value="fields.210.a.val"
                            collection="ebib_marc_ath"
                            type="v-autocomplete"
                            @search="searchRelationMarc('601', 'a', ind, 'ath', 'nume-corporatie')"
                            @input="setLink('601', 'a', ind, 'ath', 'nume-corporatie', $event)"
                          >
                            <template v-slot:append-outer>
                              <div class="d-inline-flex">
                                <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.601', ind + 1)">
                                  mdi-plus
                                </v-icon>
                                <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.601', ind)">
                                  mdi-minus
                                </v-icon>
                              </div>
                            </template>
                            <template v-slot:append>
                              <v-icon
                                class="pt-1"
                                tabindex="-1"
                                @click.stop.prevent="searchRelationMarc('601', 'a', ind, 'ath', 'nume-corporatie')"
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                          </s-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['600']" :key="'600' + ind">
                        <v-col cols="12">
                          <span class="d-none">{{ render }}</span>
                          <s-autocomplete
                            :value="fld.a[0].val"
                            where='"ctg":"nume-personal",'
                            :label="'600a ' + $t('Nume de persoană ca subiect')"
                            :dense="dense"
                            :hide-details="hided"
                            :clearable="false"
                            return-object
                            view-type="marc"
                            item-text="fields.200.a.val"
                            item-value="fields.200.a.val"
                            collection="ebib_marc_ath"
                            type="v-autocomplete"
                            @search="searchRelationMarc('600', 'a', ind, 'ath', 'nume-personal')"
                            @input="setLink('600', 'a', ind, 'ath', 'nume-personal', $event)"
                          >
                            <template v-slot:append-outer>
                              <div class="d-inline-flex">
                                <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.600', ind + 1)">
                                  mdi-plus
                                </v-icon>
                                <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.600', ind)">
                                  mdi-minus
                                </v-icon>
                              </div>
                            </template>
                            <template v-slot:append>
                              <v-icon
                                class="pt-1"
                                tabindex="-1"
                                @click.stop.prevent="searchRelationMarc('600', 'a', ind, 'ath', 'nume-personal')"
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                          </s-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['608']" :key="'608' + ind">
                        <v-col cols="12">
                          <span class="d-none">{{ render }}</span>
                          <s-autocomplete
                            :value="fld.a[0].val"
                            where='"ctg":"tezaur",'
                            :label="'608a ' + $t('Caracteristicile rubricii')"
                            :dense="dense"
                            :hide-details="hided"
                            :clearable="false"
                            return-object
                            view-type="marc"
                            item-text="fields.280.a.val"
                            item-value="fields.280.a.val"
                            collection="ebib_marc_ath"
                            type="v-autocomplete"
                            @search="searchRelationMarc('608', 'a', ind, 'ath', 'tezaur')"
                            @input="setLink('608', 'a', ind, 'ath', 'tezaur', $event)"
                          >
                            <template v-slot:append-outer>
                              <div class="d-inline-flex">
                                <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.608', ind + 1)">
                                  mdi-plus
                                </v-icon>
                                <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.608', ind)">
                                  mdi-minus
                                </v-icon>
                              </div>
                            </template>
                            <template v-slot:append>
                              <v-icon
                                class="pt-1"
                                tabindex="-1"
                                @click.stop.prevent="searchRelationMarc('608', 'a', ind, 'ath', 'tezaur')"
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                          </s-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="pl-sm-3" cols="12" sm="6">
                      <v-row v-for="(fld, ind) in record.fields['607']" :key="'607' + ind">
                        <v-col cols="12">
                          <span class="d-none">{{ render }}</span>
                          <s-autocomplete
                            :value="fld.a[0].val"
                            where='"ctg":"nume-teritorial-sau-geografic",'
                            :label="'607a ' + $t('Nume geografic ca subiect')"
                            :dense="dense"
                            :hide-details="hided"
                            :clearable="false"
                            return-object
                            view-type="marc"
                            item-text="fields.210.a.val"
                            item-value="fields.210.a.val"
                            collection="ebib_marc_ath"
                            type="v-autocomplete"
                            @search="searchRelationMarc('607', 'a', ind, 'ath', 'nume-teritorial-sau-geografic')"
                            @input="setLink('607', 'a', ind, 'ath', 'nume-teritorial-sau-geografic', $event)"
                          >
                            <template v-slot:append-outer>
                              <div class="d-inline-flex">
                                <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.607', ind + 1)">
                                  mdi-plus
                                </v-icon>
                                <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.607', ind)">
                                  mdi-minus
                                </v-icon>
                              </div>
                            </template>
                            <template v-slot:append>
                              <v-icon
                                class="pt-1"
                                tabindex="-1"
                                @click.stop.prevent="
                                  searchRelationMarc('607', 'a', ind, 'ath', 'nume-teritorial-sau-geografic')
                                "
                              >
                                mdi-magnify
                              </v-icon>
                            </template>
                          </s-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['610']" :key="'610' + ind">
                        <v-col cols="12">
                          <span class="d-none">{{ render }}</span>
                          <s-autocomplete
                            v-model="fld.a[0].val"
                            :rules="validateValue('610', 'a')"
                            :label="'610a ' + $t('Termen de subiect')"
                            :dense="dense"
                            :hide-details="hided"
                            :clearable="false"
                            view-type="marc"
                            :item-text="'fields.610.a.val'"
                            :item-value="'fields.610.a.val'"
                            collection="ebib_marc_mon"
                          >
                            <template v-slot:append-outer>
                              <div class="d-inline-flex">
                                <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.610', ind + 1)">
                                  mdi-plus
                                </v-icon>
                                <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.610', ind)">
                                  mdi-minus
                                </v-icon>
                              </div>
                            </template>
                          </s-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['615']" :key="'615' + ind">
                        <v-col cols="12">
                          <span class="d-none">{{ render }}</span>
                          <s-autocomplete
                            v-model="fld.a[0].val"
                            :rules="validateValue('615', 'a')"
                            :label="'615a ' + $t('Categoria de subiect')"
                            :dense="dense"
                            :hide-details="hided"
                            :clearable="false"
                            view-type="marc"
                            :item-text="'fields.615.a.val'"
                            :item-value="'fields.615.a.val'"
                            collection="ebib_marc_mon"
                          >
                            <template v-slot:append-outer>
                              <div class="d-inline-flex">
                                <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.615', ind + 1)">
                                  mdi-plus
                                </v-icon>
                                <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.615', ind)">
                                  mdi-minus
                                </v-icon>
                              </div>
                            </template>
                          </s-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row v-for="(fld, ind) in record.fields['686']" :key="'686' + ind">
                        <v-col cols="12">
                          <span class="d-none">{{ render }}</span>
                          <v-select
                            v-model="fld.a[0].val"
                            :rules="validateValue('686', 'a')"
                            :hide-details="hided"
                            :dense="dense"
                            item-text="name"
                            item-value="value"
                            :label="'686a ' + $t('Alte clasificări')"
                            :items="dictionary.divizionara"
                          >
                            <template v-slot:append-outer>
                              <div class="d-inline-flex">
                                <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.686', ind + 1)">
                                  mdi-plus
                                </v-icon>
                                <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.686', ind)">
                                  mdi-minus
                                </v-icon>
                              </div>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row class="mt-4" dense>
                    <v-col cols="12">
                      <v-textarea
                        v-model="record.fields['300'][0].a[0].val"
                        :rules="validateValue('300', 'a')"
                        rows="3"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'300a ' + $t('Notă generala')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="record.fields['330'][0].a[0].val"
                        :rules="validateValue('330', 'a')"
                        rows="3"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'330a ' + $t('Notă rezumat/abstract')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="record.fields['830'][0].a[0].val"
                        :rules="validateValue('830', 'a')"
                        rows="3"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'830a ' + $t('Note generale de catalogare')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-4">
                  <v-subheader>{{ "461 " + $t("Nivel set") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['461']"
                    :render="render"
                    collection="ebib_marc_mon"
                    @add="addSubItem('fields.461', $event)"
                    @del="delSubItem('fields.461', $event, 'ath')"
                    @search="searchRelationMarc('461', 't', $event, 'mon', null)"
                  />
                  <v-subheader>{{ "462 " + $t("Nivel subset") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['462']"
                    :render="render"
                    collection="ebib_marc_mon"
                    @add="addSubItem('fields.462', $event)"
                    @del="delSubItem('fields.462', $event, 'ath')"
                    @search="searchRelationMarc('462', 't', $event, 'mon', null)"
                  />
                  <v-subheader>{{ "463 " + $t("Nivel unitate") }}</v-subheader>
                  <!-- <link-fields
                    v-model="record.fields['463']"
                    :render="render"
                    collection="ebib_marc_mon"
                    @add="addSubItem('fields.463', $event)"
                    @del="delSubItem('fields.463', $event, 'ath')"
                    @search="searchRelationMarc('463', 't', $event, 'mon', null)"
                  /> -->
                  <v-row v-for="(fld, ind) in record.fields['463']" :key="'463' + ind" dense>
                    <v-col cols="12">
                      <span class="d-none">{{ render }}</span>
                      <s-autocomplete
                        :value="fld.t[0].val"
                        :label="'463t ' + $t('Nivel unitate')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        return-object
                        view-type="marc"
                        item-text="fields.200.a.val"
                        item-value="fields.200.a.val"
                        collection="ebib_marc_mon"
                        type="v-autocomplete"
                        @search="searchRelationMarc('463', 't', ind, 'mon', null)"
                        @input="setLink('463', 't', ind, 'mon', null, $event)"
                      >
                        <template v-slot:append-outer>
                          <div class="d-inline-flex">
                            <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.463', ind + 1)">
                              mdi-plus
                            </v-icon>
                            <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.463', ind)">
                              mdi-minus
                            </v-icon>
                          </div>
                        </template>
                        <template v-slot:append>
                          <v-icon
                            class="pt-1"
                            tabindex="-1"
                            @click.stop.prevent="searchRelationMarc('463', 't', ind, 'mon', null)"
                          >
                            mdi-magnify
                          </v-icon>
                        </template>
                      </s-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="fld.v[0].val"
                        :rules="validateValue('463', 'v')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'463v ' + $t('Numărul volumului')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="fld.h[0].val"
                        :rules="validateValue('463', 'h')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'463h ' + $t('Numărul secţiunii sau părţii')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="fld.d[0].val"
                        :rules="validateValue('463', 'd')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'463d ' + $t('Data publicării')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="fld.p[0].val"
                        :rules="validateValue('463', 'p')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'463p ' + $t('Descriere fizică')"
                      />
                    </v-col>
                  </v-row>
                  <v-subheader>{{ "464 " + $t("Nivel unitate analitic") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['463']"
                    :render="render"
                    collection="ebib_marc_mon"
                    @add="addSubItem('fields.463', $event)"
                    @del="delSubItem('fields.463', $event, 'ath')"
                    @search="searchRelationMarc('463', 't', $event, 'mon', null)"
                  />
                </v-tab-item>
              </base-material-tabs>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="setLinkValue" />
    <create-edit ref="createEdit" />
  </v-container>
</template>

<script>
import SearchLink from "../../../components/containers/SearchLink"
import CreateEdit from "../../../views/settings/AddDictionaryEdit"
import SAutocomplete from "../../../components/inputs/Autocomplete"
import LinkFields from "../../../components/containers/LinkFields"
// import SDateTime from "../../../components/inputs/DateTime"
// import AddImage from "../../../components/containers/AddImage"
import { EventBus } from "../../../EventBus"
import axios from "../../../plugins/axios"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: {
    SearchLink,
    CreateEdit,
    SAutocomplete,
    LinkFields
    // SDateTime,
    // AddImage
  },
  data() {
    return {
      dense: true,
      hided: false,
      fileName: "",
      file: {},
      urlImg: null,
      searchId: "",
      dictionary: {},
      linkArray: null,
      linkField: null,
      linkSubfield: null,
      linkIndex: null,
      selectId: null,
      render: 1,
      record: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          101: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], j: [{ ord: "", val: "" }] }],
          102: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }]
            }
          ],
          127: [{ ord: "", a: [{ ord: "", val: "" }] }],
          200: [
            {
              5: [{ ord: "", val: "" }],
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          207: [{ ord: "", ind2: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          210: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          211: [{ ord: "", a: [{ ord: "", val: "" }] }],
          215: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          225: [
            {
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }]
            }
          ],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          330: [{ ord: "", a: [{ ord: "", val: "" }] }],
          410: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          411: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          421: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          422: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          423: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              o: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              u: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          446: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          453: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          454: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          455: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          456: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          461: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          462: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              o: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              u: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          463: [
            {
              ord: "",
              ind2: "",
              d: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              v: [{ ord: "", val: "" }]
            }
          ],
          464: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          470: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          481: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          482: [{ 1: [{ ord: "", val: "" }], ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          488: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          510: [{ ord: "", a: [{ ord: "", val: "" }] }],
          514: [{ ord: "", a: [{ ord: "", val: "" }] }],
          516: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          545: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          600: [
            {
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          601: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }]
            }
          ],
          602: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }]
            }
          ],
          604: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }]
            }
          ],
          605: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }]
            }
          ],
          606: [
            {
              2: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              ord: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          607: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          608: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }]
            }
          ],
          610: [{ 5: [{ ord: "", val: "" }], ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          615: [
            {
              2: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }]
            }
          ],
          616: [{ ord: "", a: [{ ord: "", val: "" }], x: [{ ord: "", val: "" }], y: [{ ord: "", val: "" }] }],
          620: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          675: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          686: [{ ord: "", a: [{ ord: "", val: "" }] }],
          700: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          701: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          702: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          710: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          711: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          712: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          716: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              c: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          720: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          721: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          722: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          730: [{ 4: [{ ord: "", val: "" }], ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          802: [{ ord: "", a: [{ ord: "", val: "" }] }],
          830: [{ ord: "", a: [{ ord: "", val: "" }] }],
          850: [{ ord: "", a: [{ ord: "", val: "" }] }],
          856: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              ord: "",
              d: [{ ord: "", val: "" }],
              link: "",
              f: [{ ord: "", src_id: "", val: "" }],
              q: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          886: [{ 2: [{ ord: "", val: "" }], ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          901: [{ ord: "", a: [{ ord: "", val: "" }] }],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          920: [{ ord: "", a: [{ ord: "", val: "" }] }],
          921: [{ ord: "", a: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "010": [
            {
              6: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          "021": [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      recordView: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          101: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }], j: [{ ord: "", val: "" }] }],
          102: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }]
            }
          ],
          127: [{ ord: "", a: [{ ord: "", val: "" }] }],
          200: [
            {
              5: [{ ord: "", val: "" }],
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          207: [{ ord: "", ind2: "", a: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }],
          210: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          211: [{ ord: "", a: [{ ord: "", val: "" }] }],
          215: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          225: [
            {
              ord: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }]
            }
          ],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          330: [{ ord: "", a: [{ ord: "", val: "" }] }],
          410: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          411: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          421: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          422: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          423: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              o: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              u: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          446: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          453: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          454: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          455: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          456: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          461: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          462: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              o: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              u: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          463: [
            {
              ord: "",
              ind2: "",
              d: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              v: [{ ord: "", val: "" }]
            }
          ],
          464: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          470: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          481: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          482: [{ 1: [{ ord: "", val: "" }], ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          488: [{ ord: "", ind2: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          510: [{ ord: "", a: [{ ord: "", val: "" }] }],
          514: [{ ord: "", a: [{ ord: "", val: "" }] }],
          516: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          545: [{ ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          600: [
            {
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          601: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }]
            }
          ],
          602: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }]
            }
          ],
          604: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }],
              link: "",
              t: [{ ord: "", src_id: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }]
            }
          ],
          605: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }]
            }
          ],
          606: [
            {
              2: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              ord: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              j: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          607: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          608: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }]
            }
          ],
          610: [{ 5: [{ ord: "", val: "" }], ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          615: [
            {
              2: [{ ord: "", val: "" }],
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              m: [{ ord: "", val: "" }],
              n: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }]
            }
          ],
          616: [{ ord: "", a: [{ ord: "", val: "" }], x: [{ ord: "", val: "" }], y: [{ ord: "", val: "" }] }],
          620: [
            {
              ord: "",
              ind2: "",
              ind1: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          675: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              v: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          686: [{ ord: "", a: [{ ord: "", val: "" }] }],
          700: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          701: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          702: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          710: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          711: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          712: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              ind1: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              p: [{ ord: "", val: "" }]
            }
          ],
          716: [
            {
              3: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              c: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          720: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          721: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          722: [
            {
              3: [{ ord: "", val: "" }],
              4: [{ ord: "", val: "" }],
              5: [{ ord: "", val: "" }],
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              f: [{ ord: "", val: "" }]
            }
          ],
          730: [{ 4: [{ ord: "", val: "" }], ord: "", ind1: "", a: [{ ord: "", val: "" }] }],
          802: [{ ord: "", a: [{ ord: "", val: "" }] }],
          830: [{ ord: "", a: [{ ord: "", val: "" }] }],
          850: [{ ord: "", a: [{ ord: "", val: "" }] }],
          856: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              ord: "",
              d: [{ ord: "", val: "" }],
              link: "",
              f: [{ ord: "", src_id: "", val: "" }],
              q: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          886: [{ 2: [{ ord: "", val: "" }], ord: "", ind1: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          901: [{ ord: "", a: [{ ord: "", val: "" }] }],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          920: [{ ord: "", a: [{ ord: "", val: "" }] }],
          921: [{ ord: "", a: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "010": [
            {
              6: [{ ord: "", val: "" }],
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          "021": [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], z: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      schema: {
        dictionary: ["all.ISO-639", "all.ISO-3166", "all.divizionara"]
      },
      rows: []
    }
  },

  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {
    if (this.schema.dictionary) {
      this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
    }
    const country = []
    this.rows.map(itm => {
      country.push({ name: itm.value, value: itm.value })
    })
    EventBus.$on("dictionary-reload", dict => {
      this.$log("dictionary-reload ", dict)
      if (this.selectId == dict.id) {
        this.getDictionaryValues("all." + dict.dictionary)
        if (dict.select) {
          this.setLinkValue(dict.select)
        }
      }
    })
    // EventBus.$on("table-form-select", value => {
    //   this.$log("selected ", value)
    // })
    //this.$log(JSON.stringify(country))
    // axios.post("anaf_check_tva", [{ cui: 18158683, data: "2020-04-30" }]).then(response => {
    //   this.$log("response ", response)
    // })
  },
  methods: {
    validateValue(fld, sub) {
      const rule = []
      //rule.push(v => !!v || this.$t("required"))
      return rule
    },
    createURL(img, fil) {
      this.$set(this.dictionary, img, URL.createObjectURL(fil.target.files[0]))
    },
    fileChanged(e) {
      this.file = e.target.files[0]
      this.urlImg = URL.createObjectURL(this.file)
      this.fileName = this.file.name
    },
    clearFile() {
      this.fileName = ""
      this.file = null
    },
    createAndEdit(e, arr, field, ind) {
      //searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.selectId = Date.now()
      this.$log("createAndEdit ", e)
      this.$refs.createEdit.showData(e, this.selectId)
    },
    checkTVA(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
          }
        })
      }
    },
    loadCUIData(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
            this.record.name = response.data.found[0].denumire
            const adr = response.data.found[0].adresa.split(",")
            if (adr.length > 2) {
              this.record.address.address = response.data.found[0].adresa.replace(adr[0] + "," + adr[1] + ",", "")
            }
            this.record.address.locality = adr[1]
            this.record.address.country = "România"
            if (adr[0].substr(0, 3) == "JUD") {
              const jud = adr[0].substr(5).toLowerCase()
              this.record.address.county = adr[0].substr(5)
              this.dictionary.county.map(itm => {
                if (itm.value.toLowerCase() == jud) {
                  this.record.address.county = itm.value
                }
              })
            } else {
              this.record.address.county = "Bucureşti"
            }
          }
        })
      }
    },
    getdictionary(dic) {
      return this.$store.getters.dictionary(dic)
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        this.$set(this.dictionary, dictionary[1], this.getdictionary("all_" + dictionary[1]))
        // this.dictionary[dictionary[1]] = response.data._items[0].content
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    addSubItem(e, ind) {
      if (ind == null) {
        this.resolvePath(e, this.record).push(JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0])))
      } else {
        this.resolvePath(e, this.record).splice(
          ind,
          0,
          JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0]))
        )
      }
    },
    delSubItem(e, ind) {
      this.resolvePath(e, this.record).splice(ind, 1)
      if (this.resolvePath(e, this.record).length == 0) {
        this.addSubItem(e)
      }
    },
    searchRelationMarc(field, subfield, ind, prefix, ctg) {
      this.linkField = field
      this.linkSubfield = subfield
      this.linkIndex = ind
      //this.$log("srcrel ", this.schemaContent[fields.field + subfield])
      this.searchId = Date.now()
      let where = ""
      let vTable = ""
      if (ctg) {
        where = '"ctg":' + '"' + ctg + '"'
        const ctgview = this.$store.getters.appViewTableCollectionCategory("marc_" + prefix, ctg)
        if (ctgview.length > 0) {
          vTable = ctgview[0]._id
        }
      }
      this.$refs.searchLink.open("ebib_marc_" + prefix, null, this.searchId, {
        where: where,
        viewTable: vTable,
        ctg: ctg
      })
      // EventBus.$emit("search-link", {
      //   resource: "ebib_marc_" + prefix,
      //   field: null,
      //   id: this.searchId,
      //   config: { where: where, viewTable: vTable, ctg: ctg }
      // })
      //this.$refs.searchLink.open(res, fld, this.searchId)
    },
    searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.searchId = Date.now()
      this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setLinkValue(e) {
      if (this.linkIndex != null) {
        if (this.linkField != null) {
          //this.linkArray[this.linkIndex][this.linkField] = e
          this.$set(this.linkArray[this.linkIndex], this.linkField, e)
        } else {
          if (typeof e === "object") {
            e.iiid = this.idGlobal++
          }
          if (this.linkIndex == -1) {
            this.linkArray.push(e)
          } else {
            //this.linkArray[this.linkIndex] = e
            this.$set(this.linkArray, this.linkIndex, e)
          }
        }
      } else {
        //this.linkArray[this.linkField] = e
        this.$set(this.linkArray, this.linkField, e)
        this.render = Date.now()
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
